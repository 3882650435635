import { TPropsAttractionsPage } from '@/entities/Attractions/domain/Attractions.domain';
import { getFeaturedSectionTitle } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/utils';
import { GetStaticProps } from 'next';
import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';
import { getCategoryDescription } from '@/shared/TopAttractions/service/ApiTopAttractions';
import { attributes, parseAttr } from '@/common/app/constants/attributions';
import {
    getCardImagesWithPlaceholders,
    getPlaceholders,
} from '@/common/service/api/Activity/Activity';
import { getActivityDateless } from '@/entities/Attractions/service/ApiAttractionsPage';
import {
    getDestination,
    getCategory,
    CreateAttractionsPageTitle,
    createFaqSlug,
    createAttractionsPageMeta,
} from '@/entities/Attractions/service/Creators';
import { getFAQ } from '@/shared/FAQ/service/ApiFaq';
import AttractionsPage from 'pages/attractions/[destination]';
import { getRedirectsPath } from '@/common/service/api/Redirects/getRedirectsPath';

export async function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}

type PageStaticProps = {
    destination: string;
    category: string;
    state: string;
};

export const getStaticProps: GetStaticProps<TPropsAttractionsPage, PageStaticProps> = async ({
    params,
}) => {
    try {
        if (!params) {
            throw new Error(`PAGE THINGS TO DO /DESTINATION/CATEGORY: BAD PARAMS: ${params}`);
        }

        const currentDestination = getDestination(params.destination);
        const currentCategory = getCategory(params.category);
        const currentPath = `/destination/${params.state}/${params.destination}/things-to-do/${params.category}/`;

        if (!currentDestination || !currentCategory) {
            const redirectPath = await getRedirectsPath(currentPath);

            if (redirectPath === '/404/') {
                return {
                    notFound: true,
                };
            }

            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect: {
                    destination: redirectPath.toLowerCase(),
                    statusCode: 301,
                },
            };
        }

        const rightPath =
            `/destination/${currentDestination.state.code}/${currentDestination.slug}/things-to-do/${currentCategory.slug}/`.toLowerCase();

        if (currentPath !== rightPath) {
            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect: {
                    destination: rightPath,
                    statusCode: 301,
                },
            };
        }

        const title = CreateAttractionsPageTitle(currentDestination, currentCategory);
        const featuredActivitiesTitle = getFeaturedSectionTitle(
            currentDestination.name,
            currentCategory.name
        );

        const faqSlug = createFaqSlug(currentDestination, currentCategory);

        const breadcrumbs = [
            {
                url: '/destination/',
                title: 'Destination',
            },
            {
                url: rightPath.split('things-to-do/')[0],
                title: currentDestination.name,
            },
            {
                title: 'Things to do',
                url: rightPath.replace(currentCategory.slug.toLowerCase() + '/', ''),
            },
            {
                url: rightPath,
                title: currentCategory.name,
                unlink: true,
            },
        ];

        const [fullList, faq, recommends, categoryDescription] = await Promise.all([
            getActivityDateless(currentDestination.id, currentCategory.id),
            getFAQ(faqSlug),
            getRecommends({
                category_id: currentCategory.id,
                destination_id: currentDestination.id,
            }),
            getCategoryDescription(`${currentDestination?.slug}_${currentCategory?.slug}`),
        ]);

        if (
            !fullList.total &&
            !currentDestination.categories?.find((i) => i.id === currentCategory.id)
                ?.totalActivities
        ) {
            return {
                redirect: {
                    destination: `/404/`,
                    statusCode: 301,
                },
            };
        }

        const meta = createAttractionsPageMeta(currentDestination, currentCategory, fullList.total);

        const availableAttributes: number[] = [];
        const ids = fullList.items.map(({ id }) => id);

        const [images, placeholders] = await Promise.all([
            getCardImagesWithPlaceholders(ids),
            getPlaceholders(ids, 'activity'),
        ]);

        fullList.items.forEach((trip) => {
            trip.price = 0;
            trip.images = images[trip.id] || [];
            trip.placeholder = placeholders[trip.id] || '';
            if (trip.attributes) {
                const attrs = parseAttr(trip.attributes);
                availableAttributes.push(...attrs.filter((i) => !availableAttributes.includes(i)));
            }
        });

        return {
            revalidate: Number(process.env.CACHE_ENV),
            props: {
                meta,
                breadcrumbs,
                title,
                featuredActivitiesTitle,
                fullList,
                faq,
                currentDestination,
                currentCategory,
                isEmpty: !fullList.total,
                noRecommends: !recommends.length,
                categoryDescription,
                availableAttributes: attributes.filter((i) => availableAttributes.includes(i.id)),
                placeholders,
            },
        };
    } catch (e) {
        throw new Error((e as Error).message);
    }
};

export default AttractionsPage;
